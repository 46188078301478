import Rails from "@rails/ujs"

function handleFormSubmission (form, fields = []) {
  fields.forEach(field => {
    if (field.name) appendField(form, field.name, field.value)
  })

  if (form.dataset.remote === 'true') {
    Rails.fire(form, 'submit')
  } else {
    form.submit()
  }
}

function appendField (form, name, value) {
  const input = document.createElement("input")
  input.type = "hidden"
  input.value = value
  input.name = name
  form.appendChild(input)
}

export { handleFormSubmission, appendField }
