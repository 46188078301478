import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  INPUT_TAG_NAMES = ['INPUT', 'SELECT']

  static targets = [
    'form'
  ]

  connect () {
    // Wait until next click
    setTimeout(() => {
      this.connected = true
      this.#calculateFormsHash()
      this.formTargets.forEach(form => this.#setEventListeners(form))
    })
  }

  formTargetConnected (form) {
    if (!this.connected) return

    setTimeout(() => {
      this.#calculateFormsHash()
      this.#setEventListeners(form)
    })
  }

  #setEventListeners (form) {
    this.#formInputs(form).forEach(input => {
      input.addEventListener('change', () => this.#calculateFormsHash())
    })
  }

  #calculateFormsHash () {
    // check each form for edition or calculate and store its hash
    this.formTargets.forEach(form => {
      if (form.hasAttribute('data-original-hash')) {
        form.dataset.currentHash = this.#calculateFormHash(form)
      } else {
        form.dataset.originalHash = this.#calculateFormHash(form)
      }
    })
    this.dispatch("change")
  }

  #formInputs (form) {
    return Array.from(form).filter(el => this.INPUT_TAG_NAMES.includes(el.tagName))
  }

  #calculateFormHash (form) {
    return this.#formInputs(form).map(input => input.checked || input.value).join('').replace(/\s/g, '')
  }
}
