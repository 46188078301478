import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input']

  fill ({ params: { payload } }) {
    if (payload) {
      Object.entries(payload).forEach(([attribute, value]) => {
        this.inputTargets.forEach(input => {
          const match = input.name.match(/\[(\w+)\]$/)
          const inputName = match ? match[1] : input.name

          if (inputName === attribute) {
            this.#changeInputValue(input, value)
          }
        })
      })
    } else {
      this.inputTargets.forEach(input => {
        this.#changeInputValue(input, "")
      })
    }
  }

  #changeInputValue (input, value) {
    if (input.type === "checkbox") {
      input.checked = value
    } else {
      input.value = value
    }
    input.dispatchEvent(new Event('change'))
  }
}
