import { Controller } from '@hotwired/stimulus'
import Cleave from 'cleave.js'

export default class extends Controller {
  static targets = [
    "operationSelect",
    "itemsList",
    "itemRow",
    "budgetAmountInput",
    "amountInput",
    "percentageInput",
    "programAmount",
    "programPercentage",
    "selectionCheckbox",
    "totalRow",
    "totalPercentage",
    "totalAmount",
    "itemBalance",
    "newBalanceExceededAlert",
    "allBudgetNotAllocatedAlert"
  ]

  static values = {
    endpoint: String
  }

  initialize () {
    this.#initCleave(this.budgetAmountInputTarget)
  }

  totalRowTargetConnected () {
    this.#checkItems()
  }

  amountInputTargetConnected (target) {
    this.#initCleave(target)
  }

  amountInputTargetDisconnected (target) {
    target.cleave?.destroy()
  }

  percentageInputTargetConnected (target) {
    this.#initCleave(target)
  }

  percentageInputTargetDisconnected (target) {
    target.cleave?.destroy()
  }

  updateItemsList () {
    fetch(this.#url())
      .then(response => response.text())
      .then(html => {
        this.itemsListTarget.outerHTML = html
      })
  }

  updateItemAmount (event) {
    const amountInput = event.target.closest('tr').querySelector(`[data-${this.identifier}-target="amountInput"]`)
    amountInput.cleave.setRawValue((this.#cleaveInputRawValue(event.target) * this.budgetAmount / 100).toFixed(2))
    this.#checkItems()
  }

  updateItemPercentage (event) {
    const percentageInput = event.target.closest('tr').querySelector(`[data-${this.identifier}-target="percentageInput"]`)
    percentageInput.cleave.setRawValue((this.#cleaveInputRawValue(event.target) / this.budgetAmount * 100).toFixed(2))
    this.#checkItems()
  }

  updateItemsAmount () {
    this.amountInputsOfSelectedItems.forEach(input => {
      const percentageInput = input.closest('tr').querySelector(`[data-${this.identifier}-target="percentageInput"]`)
      input.cleave.setRawValue((this.#cleaveInputRawValue(percentageInput) * this.budgetAmount / 100).toFixed(2))
    })
    this.#checkItems()
  }

  toogleItem (event) {
    const row = event.target.closest("tr")
    const fieldsBasename = row.dataset.fieldsBasename

    row.classList.toggle("text-muted", !event.target.checked)

    this.element.querySelectorAll(`input[name^='${fieldsBasename}']:not([type=hidden]):not([type=checkbox])`).forEach(input => {
      input.disabled = !event.target.checked
      if (!event.target.checked) input.value = ""
    })

    const destroyField = this.element.querySelector(`input[name^='${fieldsBasename}[_destroy]']`)
    destroyField.disabled = event.target.checked
    destroyField.value = (!event.target.checked).toString()

    if (!event.target.checked) this.#balanceItemsPercentage()
    this.#calculateProgramsPercentage()
    this.#checkItems()
  }

  // Private functions ========================================================

  #url () {
    const url = new URL(this.endpointValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append("operation_id", this.operationSelectTarget.value)
    url.search = params.toString()
    return url
  }

  #balanceItemsPercentage () {
    const percentageInputsOfSelectedItems = this.percentageInputsOfSelectedItems
    const valuePerItem = (100 - this.totalAllocatedPercentageOfSelectedItems) / percentageInputsOfSelectedItems.length

    percentageInputsOfSelectedItems.forEach(input => {
      input.cleave.setRawValue(parseFloat(input.cleave.getRawValue()) + valuePerItem)
    })
    this.updateItemsAmount()
  }

  #calculateProgramsPercentage () {
    const ProgramsTotalAmount = this.ProgramsTotalAmountOfSelectedItems

    this.programPercentageTargets.forEach(el => {
      const row = el.closest('tr')
      const isItemSelected = row.querySelector(`[data-${this.identifier}-target="selectionCheckbox"]`).checked

      if (isItemSelected) {
        const programAmount = parseFloat(row.querySelector(`[data-${this.identifier}-target="programAmount"]`).dataset.rawValue)
        el.innerText = this.#percentFormat(programAmount / ProgramsTotalAmount)
      } else {
        el.innerText = null
      }
    })
  }

  #checkItems () {
    const totalAllocatedPercentage = this.totalAllocatedPercentageOfSelectedItems
    const isTotalAllocatedPercentageValid = totalAllocatedPercentage === 100
    const isTotalAllocatedAmountValid = this.budgetAmount ? this.totalAllocatedAmountOfSelectedItems === this.budgetAmount : true

    this.totalPercentageTarget.innerText = this.#percentFormat(totalAllocatedPercentage / 100)
    this.totalPercentageTarget.classList.toggle("text-success", isTotalAllocatedPercentageValid)
    this.totalPercentageTarget.classList.toggle("text-danger", !isTotalAllocatedPercentageValid)

    if (this.hasTotalAmountTarget) {
      this.totalAmountTarget.innerText = this.budgetAmount ? new Intl.NumberFormat('fr-FR').format(this.totalAllocatedAmountOfSelectedItems) : ""
      this.totalAmountTarget.classList.toggle("text-success", isTotalAllocatedAmountValid)
      this.totalAmountTarget.classList.toggle("text-danger", !isTotalAllocatedAmountValid)
    }

    this.allBudgetNotAllocatedAlertTarget.hidden = isTotalAllocatedPercentageValid && isTotalAllocatedAmountValid

    this.itemRowTargets.forEach(row => {
      this.#checkItemNewBalance(row)
    })
  }

  #checkItemNewBalance (row) {
    const itemAmount = this.#cleaveInputRawValue(row.querySelector(`[data-${this.identifier}-target="amountInput"]`)) || 0
    const itemBalanceEl = row.querySelector(`[data-${this.identifier}-target="itemBalance"]`)
    const itemBalance = itemBalanceEl?.dataset?.rawValue || 0
    const alert = row.querySelector(`[data-${this.identifier}-target="newBalanceExceededAlert"]`)

    if (itemBalanceEl && itemAmount > 0 && itemAmount > itemBalance) {
      row.classList.toggle("bg-warning", true)
      alert.hidden = false
    } else {
      row.classList.toggle("bg-warning", false)
      alert.hidden = true
    }
  }

  #initCleave (target) {
    target.cleave = new Cleave(target, {
      numeral: true,
      delimiter: ' ',
      numeralPositiveOnly: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralDecimalScale: 2
    })
  }

  #percentFormat (value) {
    return value.toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 })
  }

  #cleaveInputRawValue (input) {
    return parseFloat(input.cleave.getRawValue())
  }

  // Getters ==================================================================

  get budgetAmount () {
    return this.#cleaveInputRawValue(this.budgetAmountInputTarget)
  }

  get percentageInputsOfSelectedItems () {
    return this.percentageInputTargets.filter(input => !input.disabled)
  }

  get amountInputsOfSelectedItems () {
    return this.amountInputTargets.filter(input => !input.disabled)
  }

  get totalAllocatedPercentageOfSelectedItems () {
    return this.percentageInputsOfSelectedItems.reduce((accumulator, el) => {
      return accumulator + (this.#cleaveInputRawValue(el) || 0)
    }, 0)
  }

  get totalAllocatedAmountOfSelectedItems () {
    return this.amountInputsOfSelectedItems.reduce((accumulator, el) => {
      return accumulator + (this.#cleaveInputRawValue(el) || 0)
    }, 0)
  }

  get ProgramsTotalAmountOfSelectedItems () {
    return this.amountInputsOfSelectedItems.reduce((accumulator, el) => {
      const programAmount = parseFloat(el.closest('tr').querySelector(`[data-${this.identifier}-target="programAmount"]`).dataset.rawValue)
      return accumulator + programAmount
    }, 0)
  }
}
