import { Controller } from '@hotwired/stimulus'
import { handleFormSubmission } from "../../utils/form.js"
import { Tooltip } from 'bootstrap'

export default class extends Controller {
  static values = {
    reservationPrice: Number
  }

  static targets = [
    'pinelFieldset',
    'pinelTab',
    'cashPurchaseFieldset',
    'feeAmountInput',
    'cosignerDocumentsFieldset',
    'form',
    'submitFieldset',
    'editIcon',
    'validIcon',
    'invalidIcon'
  ]

  togglePinelElements (event) {
    const disabled = event.target.value !== "pinel"

    this.pinelFieldsetTarget.disabled = disabled
    this.pinelFieldsetTarget.hidden = disabled
    this.pinelTabTarget.hidden = disabled
  }

  toggleCashPurchaseFieldset (event) {
    const disabled = event.target.value === "true"

    this.cashPurchaseFieldsetTarget.disabled = disabled
    this.cashPurchaseFieldsetTarget.hidden = disabled
  }

  updateTotalPrice (event) {
    const feeRate = (event.target.cleave ? event.target.cleave.getRawValue() : event.target.value) || 0

    if (this.feeAmountInputTarget.cleave) {
      this.feeAmountInputTarget.cleave.setRawValue(this.reservationPriceValue * feeRate / 100)
    } else {
      this.feeAmountInputTarget.value = this.reservationPriceValue * feeRate / 100
    }
  }

  toggleCosignerDocumentsFieldset (event) {
    const enabled = event.target.checked
    this.cosignerDocumentsFieldsetTarget.hidden = !enabled
    this.cosignerDocumentsFieldsetTarget.disabled = !enabled
  }

  updateLayout () {
    const editedForms = this.formTargets.filter(form => form.hasAttribute("data-current-hash") && form.dataset.currentHash !== form.dataset.originalHash)
                                        .map(form => form.id)

    this.editIconTargets.forEach(el => {
      el.hidden = !editedForms.includes(el.dataset.formId)
    })

    this.invalidIconTargets.forEach(el => {
      const form = this.formTargets.find(form => form.id === el.dataset.formId)
      el.hidden = form.hasAttribute('data-record-valid') ? form.dataset.recordValid === "true" : true
    })

    this.validIconTargets.forEach(el => {
      const form = this.formTargets.find(form => form.id === el.dataset.formId)
      el.hidden = form.hasAttribute('data-record-valid') ? form.dataset.recordValid === "false" : true
    })

    const areInvalidForms = this.formTargets.some(form => {
                              return form.dataset.recordValid === "false" && (!form.contains(this.pinelFieldsetTarget) || !this.pinelFieldsetTarget.disabled)
                            })

    this.submitFieldsetTargets.forEach(fieldset => {
      if (areInvalidForms) {
        fieldset.title = "Les informations de la réservation et/ou du client sont incomplètes"
      } else if (editedForms.length > 0) {
        fieldset.title = "Les informations de la réservation et/ou du client ont été modifiées"
      }

      const disabled = areInvalidForms || editedForms.length > 0
      fieldset.disabled = disabled

      if (disabled) {
        Tooltip.getOrCreateInstance(fieldset)
      } else {
        Tooltip.getInstance(fieldset)?.dispose()
        fieldset.removeAttribute("title")
      }
    })
  }

  submit (event) {
    handleFormSubmission(event.target.closest('form'))
  }
}
