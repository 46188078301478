import { Controller } from '@hotwired/stimulus'
import intlTelInput from 'intl-tel-input'
import Cleave from 'cleave.js'
import 'cleave.js/dist/addons/cleave-phone.i18n.js'
import 'intl-tel-input/build/css/intlTelInput.min.css'

export default class extends Controller {
  static targets = ['input']

  connect () {
    if (!this.hasInputTarget) return

    this.intlTelInput = intlTelInput(this.inputTarget, {
      preferredCountries: ['FR'],
      initialCountry: 'FR',
      separateDialCode: false,
      nationalMode: false,
      formatOnDisplay: false,
      autoHideDialCode: true
    })

    this.cleave = new Cleave(this.inputTarget, {
      phone: true,
      phoneRegionCode: this.intlTelInput.getSelectedCountryData().iso2
    })

    this.boundUpdateCountryCode = this.updateCountryCode.bind(this)
    this.inputTarget.addEventListener("countrychange", this.boundUpdateCountryCode)

    this.boundSetDialCode = this.setDialCode.bind(this)
    this.inputTarget.addEventListener("focus", this.boundSetDialCode)

    this.boundFormatValue = this.formatValue.bind(this)
    this.inputTarget.addEventListener("blur", this.boundFormatValue)
  }

  disconnect () {
    this.inputTarget?.removeEventListener("countrychange", this.boundUpdateCountryCode)
    this.inputTarget?.removeEventListener("focus", this.boundSetDialCode)
    this.intlTelInput?.destroy()
    this.cleave?.destroy()
  }

  updateCountryCode () {
    this.cleave.properties.phoneRegionCode = this.intlTelInput.getSelectedCountryData().iso2
    this.cleave.initPhoneFormatter()
    this.cleave.setRawValue(this.cleave.getRawValue())
  }

  setDialCode () {
    if (!this.cleave.getRawValue()) {
      this.cleave.setRawValue("+" + this.intlTelInput.selectedCountryData.dialCode)
    }
  }

  formatValue () {
    if (this.cleave.getRawValue() && this.cleave.getRawValue() === this.cleave.getFormattedValue()) {
      const value = this.cleave.getRawValue().startsWith('+') ? this.cleave.getRawValue() : `+${this.cleave.getRawValue()}`
      this.cleave.setRawValue(value)
    }
  }
}
