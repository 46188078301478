import { Controller } from '@hotwired/stimulus'
import { flashFromAjaxEvent } from "../../utils/flash-messages"

export default class extends Controller {
  SUCCESS_AJAX_EVENTS = ["ajax:complete", "ajax:success"]

  static targets = [
    'target',
    'loaderTemplate'
  ]

  replaceOuter (event) {
    flashFromAjaxEvent(event)
    this.#getTarget(event).outerHTML = this.#getResponseBody(event)
    this.loader?.remove()
  }

  replaceInner (event) {
    flashFromAjaxEvent(event)
    this.#getTarget(event).innerHTML = this.#getResponseBody(event)
    this.loader?.remove()
  }

  prepend (event) {
    flashFromAjaxEvent(event)
    this.#getTarget(event).insertAdjacentHTML('beforebegin', this.#getResponseBody(event))
    this.loader?.remove()
  }

  append (event) {
    flashFromAjaxEvent(event)
    this.#getTarget(event).insertAdjacentHTML('afterend', this.#getResponseBody(event))
    this.loader?.remove()
  }

  redirectToLocation (event) {
    flashFromAjaxEvent(event)
    const location = this.#getLocation(event)
    if (location) window.location = location
  }

  // Display a loader where the response is supposed to be injected if a template with a data-remote-target="loaderTemplate"
  // containing the loader's HTML code is present within the controller's scope
  // The loader is automatically removed once the response injected
  //
  showLoader () {
    if (!this.hasLoaderTemplateTarget) return

    const target = this.#getTarget()

    if (["replaceInner", "replaceOuter"].includes(this.#successMethodName)) {
      target.childNodes.forEach(child => {
        // check if the childNode has the `setAttribute` function before calling it
        // In development, Rails injects the partials name as HTML comments inside the page which do not respond to the `setAttribute` function
        if (child.setAttribute) child.setAttribute('style', 'display: none !important')
      })
      target.insertAdjacentHTML('beforeend', this.loaderTemplateTarget.innerHTML)
      this.loader = target.lastChild
    } else if (this.#successMethodName === "append") {
      target.insertAdjacentHTML('afterend', this.loaderTemplateTarget.innerHTML)
      this.loader = target.nextSibling
    } else if (this.#successMethodName === "prepend") {
      target.insertAdjacentHTML('beforebegin', this.loaderTemplateTarget.innerHTML)
      this.loader = target.previousSibling
    }
  }

  #getXhrFromEvent (event) {
    if (!event) return

    return event.type === "ajax:complete" ? event.detail[0] : event.detail[2]
  }

  #getResponseBody (event) {
    return this.#getXhrFromEvent(event)?.response
  }

  #getLocation (event) {
    const xhr = this.#getXhrFromEvent(event)
    return xhr.getResponseHeader('location') || xhr.responseURL
  }

  // the target is resolved in the following manner:
  //   1) 'X-Remote-Target' header: if this header is present, the target is the DOM element that has the same Id as the header's value
  //   2) Stimulus target 'target' (this.targetTarget) if the 'X-Remote-Target' header is not present
  //   3) This.element (the DOM element on which this controller is attached) if the controller has no 'target' target
  //
  #getTarget (event) {
    const targetId = this.#getXhrFromEvent(event)?.getResponseHeader('x-remote-target')

    if (targetId) {
      return document.getElementById(targetId)
    } else if (this.hasTargetTarget) {
      return this.targetTarget
    } else {
      return this.element
    }
  }

  // Return the name of the function that is called on successful response, either by an "ajax:complete" or "ajax:success" data-action
  // The plausible names are: replaceOuter, replaceInner, prepend, append or redirectToLocation
  // data-action="ajax:success->remote#replaceOuter" -> returns "replaceOuter"
  // data-action="ajax:complete->remote#prepend" -> returns "prepend"
  //
  get #successMethodName () {
    return (this._successMethodName ||= this.#controllerInstance.context.bindingObserver.bindings.find(binding => this.SUCCESS_AJAX_EVENTS.includes(binding.eventName)).methodName)
  }

  // Return the controller instance
  //
  get #controllerInstance () {
    return this.application.getControllerForElementAndIdentifier(this.element, this.identifier)
  }
}
